import React from 'react';

const PDPTimelinePage = (props) => {
  return (
    <div className="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th colSpan="2">PDP Semester Timeline</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="text-nowrap">Week 0 & 1</span>
              </td>
              <td>Add/Drop PD for Current Semester</td>
            </tr>
            <tr>
              <td>Week 2</td>
              <td>PDCs/Small Group PDC's Begin</td>
            </tr>
            <tr>
              <td>Week 5</td>
              <td>Proposal Submission Week for Next Semester</td>
            </tr>
            <tr>
              <td>
                <span className="text-nowrap">Week 6 & 7</span>
              </td>
              <td>Proposal Selection by PDP Manager/Committee</td>
            </tr>
            <tr>
              <td>Week 8</td>
              <td>PD Registration for Next Semester</td>
            </tr>
            <tr>
              <td>Week 9</td>
              <td>Manager Approvals of Registration Requests</td>
            </tr>
            <tr>
              <td>Week 10</td>
              <td>
                DMT Review of any Registration Issues, registration request approval/denial received
              </td>
            </tr>
            <tr>
              <td>Week 13</td>
              <td>PDC Evaluations Open</td>
            </tr>
            <tr>
              <td>Week 14</td>
              <td>
                <p>PDC/Small Group PDC's End, Evaluations Close (Friday)</p>
                <p>All Activity Report Forms signed by Friday (for CEU-based PD)</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PDPTimelinePage;
